<template>
  <div v-if="!isCollapse" id="left-menu" class="left-menu">
    <div class="layout-icon">
      <h1
        style="font-size: 20px; cursor: pointer; color: black"
        class="iconfont icon-a-logout2"
        @click="layoutKnowledge"
      ></h1>
      <div class="toolPopoverBox" v-show="showToolPopoverBox">
        <el-popover placement="top" trigger="click" popper-class="toolPopover">
          <div class="left-menu-middle">
            <el-popover
              id="collectionIcon"
              placement="bottom-end"
              width="1.5625rem"
              trigger="click"
              popper-class="collection-popper-class"
              @show="getCollections"
            >
              <el-input
                v-model="collectionSearch"
                size="mini"
                :placeholder="$t('knowledge.PleaseEnterContent')"
                suffix-icon="el-icon-search"
              >
              </el-input>
              <div
                class="collection-search-wrap"
                style="height: 188px; overflow-y: scroll; margin-top: 6px"
              >
                <div
                  v-for="(item, index) in searchData"
                  :key="index"
                  @click.prevent="showdoc(item)"
                >
                  <span
                    ><i
                      class="el-icon-document"
                      style="width: 16px; height: 16px"
                    ></i
                  ></span>
                  <span>{{ item.label }}</span>
                  <span>
                    <i
                      class="iconfont icon-edit"
                      style="font-size: 15px"
                      @click.stop="editdoc(item)"
                    >
                    </i>
                  </span>
                </div>
              </div>
              <i
                slot="reference"
                class="iconfont icon-knowledge-collection"
                style="
                  font-size: 22px;
                  cursor: pointer;
                  color: rgb(48, 100, 143);
                "
              ></i>
              <i
                v-if="projectRole === 'MANAGER'"
                slot="reference"
                class="iconfont icon-internetShow"
                style="
                  font-size: 22px;
                  cursor: pointer;
                  color: rgb(48, 100, 143);
                  margin-left: 10px;
                "
                @click="showInternetList"
              ></i>
            </el-popover>
            <span @click="toBin" style="margin-right: 0; margin-left: 10px">
              <i
                class="iconfont icon-delete"
                style="
                  font-size: 20px;
                  color: rgba(48, 100, 143, 1);
                  cursor: pointer;
                "
              ></i>
            </span>
          </div>
          <div slot="reference" style="width: 30px">
            <span
              style="
                color: rgba(48, 100, 143, 1);
                font-size: 20px;
                font-weight: bold;
                letter-spacing: -3px;
                cursor: pointer;
              "
            >
              ···
            </span>
          </div>
        </el-popover>
      </div>
      <div
        class="left-menu-middle"
        id="leftMenuTopToolBox"
        v-show="!showToolPopoverBox"
      >
        <el-popover
          id="collectionIcon"
          placement="bottom-end"
          width="1.5625rem"
          trigger="click"
          popper-class="collection-popper-class"
          @show="getCollections"
        >
          <el-input
            v-model="collectionSearch"
            size="mini"
            :placeholder="$t('knowledge.PleaseEnterContent')"
            suffix-icon="el-icon-search"
          >
          </el-input>
          <div
            class="collection-search-wrap"
            style="height: 188px; overflow-y: scroll; margin-top: 6px"
          >
            <div
              v-for="(item, index) in searchData"
              :key="index"
              @click.prevent="showdoc(item)"
            >
              <span
                ><i
                  class="el-icon-document"
                  style="width: 16px; height: 16px"
                ></i
              ></span>
              <span>{{ item.label }}</span>
              <span>
                <i
                  class="iconfont icon-edit"
                  style="font-size: 15px"
                  @click.stop="editdoc(item)"
                >
                </i>
              </span>
            </div>
          </div>
          <i
            slot="reference"
            class="iconfont icon-knowledge-collection"
            style="font-size: 22px; cursor: pointer; color: rgb(48, 100, 143)"
          ></i>
          <i
            v-if="projectRole === 'MANAGER'"
            slot="reference"
            class="iconfont icon-internetShow"
            style="
              font-size: 22px;
              cursor: pointer;
              color: rgb(48, 100, 143);
              margin-left: 10px;
            "
            @click="showInternetList"
          ></i>
        </el-popover>
        <span @click="toBin">
          <i
            class="iconfont icon-delete"
            style="
              font-size: 20px;
              color: rgba(48, 100, 143, 1);
              cursor: pointer;
            "
          ></i>
        </span>
      </div>
    </div>
    <div class="left-menu-top">
      <span
        class="add-button"
        :class="{
          disabled: !draggable || (checkedNode && !checkedNode.authList.length),
        }"
        @click="addNode"
        ><i
          class="el-icon-plus"
          style="font-size: 20px; font-weight: 700; vertical-align: middle"
        ></i
      ></span>
    </div>

    <div class="left-menu-content" style="overflow-y: auto">
      <el-tree
        ref="tree"
        :data="menuList"
        node-key="documentId"
        :draggable="draggable"
        highlight-current
        :default-expanded-keys="defaultExpandedKeys"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        style="background: rgba(242, 246, 250, 1)"
        @node-drag-start="handleDragStart"
        @node-drag-enter="handleDragEnter"
        @node-drag-leave="handleDragLeave"
        @node-drag-over="handleDragOver"
        @node-drag-end="handleDragEnd"
        @node-drop="handleDrop"
        @node-click="nodeClick"
        @node-expand="hanlderExpand"
        @node-collapse="handleCollapse"
      >
        <span slot-scope="{ node, data }" class="custom-tree-node">
          <span v-if="data.authList.length">
            <a
              style="color: #606266"
              :href="
                '/' + get_pid() + '/knowledge?documentId=' + data.documentId
              "
              @click.prevent="handleClickA"
              >{{ data.label }}</a
            ></span
          >
          <span v-else class="mosaic">
            <i class="iconfont icon-userLock"></i>
            {{ $t("knowledge.noPermission") }}</span
          >
        </span>
      </el-tree>
    </div>
    <div class="left-menu-bottom">
      <div @click="collpaseAside">
        <i
          class="iconfont icon-shouqi1"
          style="
            font-size: 24px;
            margin-right: 12px;
            color: rgba(128, 128, 128, 1);
          "
        ></i>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="internetDialog"
      :append-to-body="true"
      :show-close="true"
      @close="handleClose"
      width="50%"
      style="margin-top: 60px"
    >
      <div class="createpro-header">
        <el-tabs v-model="activeButton">
          <el-tab-pane name="edit">
            <template slot="label"
              ><span class="tab-name">{{
                $t("knowledge.InternetEditable")
              }}</span></template
            >
            <el-table
              :data="
                internetEdit.slice(
                  (currentPageEdit - 1) * pageSize,
                  currentPageEdit * pageSize
                )
              "
              style="width: 100%"
              :show-header="false"
              class="table-container"
            >
              <el-table-column prop="label" label="">
                <template slot-scope="scope">
                  <span
                    @click="routeToDoc(scope.row)"
                    class="article-name"
                    style="cursor: pointer"
                    >{{ scope.row.label }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div
              style="
                display: flex;
                justify-content: center;
                font-size: 14px;
                font-weight: 400;
                color: rgba(112, 112, 112, 1);
              "
            >
              <el-pagination
                @current-change="handleCurrentChangeEdit"
                :current-page="currentPageEdit"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="internetEdit.length"
                background
                :pager-count="5"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane name="read">
            <template slot="label"
              ><span class="tab-name">{{
                $t("knowledge.InternetReadable")
              }}</span></template
            >
            <el-table
              :data="
                internetShow.slice(
                  (currentPageRead - 1) * pageSize,
                  currentPageRead * pageSize
                )
              "
              style="width: 100%"
              :show-header="false"
              class="table-container"
            >
              <el-table-column prop="label" label="">
                <template slot-scope="scope">
                  <span
                    @click="routeToDoc(scope.row)"
                    class="article-name"
                    style="cursor: pointer"
                    >{{ scope.row.label }}</span
                  >
                </template>
              </el-table-column>
            </el-table>

            <div style="display: flex; justify-content: center">
              <el-pagination
                @current-change="handleCurrentChangeRead"
                :current-page="currentPageRead"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="internetShow.length"
                background
                :pager-count="5"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
  <div v-else class="left-menu-hide" @click="collpaseAside">
    <i
      class="iconfont icon-zhedie1"
      style="font-size: 24px; color: rgba(128, 128, 128, 1)"
    ></i>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import {
  addDocuments,
  location,
  getCollections,
  payFuture,
  getAuth,
  getInternetShowList,
} from "@/network/knowledge";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

export default {
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      lastChecked: null,
      isCollapse: false,
      draggable: true,
      collectionSearch: "",
      hasPay: false,
      allSearchData: [],
      defaultExpandedKeys: [],
      internetShow: [],
      internetEdit: [],
      internetDialog: false,
      activeButton: "edit",
      currentPageRead: 1,
      currentPageEdit: 1,
      pageSize: 7,
      dragNode: null,
      showToolPopoverBox: false,
    };
  },
  computed: {
    ...mapGetters(["token", "userAccountId", "projectRole"]),
    searchData() {
      const data = [];
      if (this.collectionSearch) {
        this.allSearchData.filter((item) => {
          if (item.label.indexOf(this.collectionSearch) !== -1) {
            data.push(item);
          }
        });
      } else {
        return this.allSearchData;
      }
      return data;
    },
  },
  watch: {
    menuList: {
      handler(newval) {
        if (newval && newval.length) {
          this.getQuery();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // 查询知识库是否付费
    this.payFuture();
    if (document.querySelector(".left-menu-middle")) {
      document
        .querySelector(".left-menu-middle")
        .addEventListener("click", this.addHotArea);
      document
        .querySelector(".left-menu-content")
        .addEventListener("click", this.addHotArea);
    }
    this.titleChange();
  },
  beforeDestroy() {
    this.closeWebsocket();
  },
  methods: {
    ...mapMutations(["SET_KNOWLEDGE_EDIT"]),
    handleClickA() {},
    layoutKnowledge() {
      this.$router.push({
        path: `/${this.$route.params.projectId}/home/my_file`,
      });
    },
    titleChange() {
      if (
        this.checkedNode &&
        this.checkedNode.label !== this.$route.query.title
      ) {
        const query = {
          documentId: this.checkedNode.documentId,
        };
        this.checkedNode.authList && this.checkedNode.authList.length
          ? (query.title = this.checkedNode.label)
          : "";
        this.$route.query && this.$route.query.commentId
          ? (query.commentId = this.$route.query.commentId)
          : "";
        this.$router.push({
          name: "knowledge",
          params: {
            projectId: this.get_pid(),
          },
          query: query,
        });
      }
    },
    showInternetList() {
      const params = {
        projectId: this.get_pid(),
      };
      getInternetShowList(params).then((res) => {
        this.internetShow = res.internetReadList ? res.internetReadList : [];
        this.internetEdit = res.internetEditList ? res.internetEditList : [];
      });
      this.internetDialog = true;
    },
    handleCurrentChangeEdit(val) {
      this.currentPageEdit = val;
    },
    handleCurrentChangeRead(val) {
      this.currentPageRead = val;
    },
    handleClose() {
      this.activeButton = "edit";
      this.currentPageEdit = 1;
      this.currentPageRead = 1;
    },
    routeToDoc(e) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/knowledge?documentId=${e.documentId}&title=${
          e.label
        }`,
      });
      window.open(href, "_blank");
    },
    // 添加热点区域点击取消节点选择
    addHotArea(e) {
      if (e.target.classList.contains("iconfont")) {
        return;
      }
      if (e.target.classList.contains("el-tree")) {
        return;
      }
      this.checkedNode = null;
      this.lastChecked = null;
      this.$refs.tree.setCurrentKey(null);
    },
    // 默认展开节点
    buildDefaultExpandedKeys() {
      if (this.checkedNode && this.checkedNode.parentId) {
        this.defaultExpandedKeys.push(this.checkedNode.parentId);
      }
    },
    payFuture() {
      const params = {
        projectId: this.get_pid(),
      };
      payFuture(params).then((res) => {
        this.hasPay = res;
        this.initWebscoket();
      });
    },
    // 获取参数
    getQuery() {
      if (this.$route.query && this.$route.query.documentId) {
        this.$nextTick(() => {
          const node = this.$refs.tree
            ? this.$refs.tree.getNode(this.$route.query.documentId)
            : null;
          if (!node) {
            this.$message({
              type: "warning",
              message: "该文章不存在",
            });
          } else {
            this.$emit("checked", node.data);
            this.$nextTick(() => {
              this.$refs.tree
                ? this.$refs.tree.setCurrentKey(this.checkedNode.documentId)
                : "";
              this.buildDefaultExpandedKeys();
            });
          }
        });
      }
    },
    initWebscoket() {
      const self = this;
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer?accountId=${this.userAccountId}`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "KNOWLEDGE_DOC_TREE_MODIFY",
        }, //传递token
        (frame) => {
          // 测试topic
          this.connected = true;
          this.stompClient.subscribe(
            `/topic/KNOWLEDGE_DOC_TREE_MODIFY/${this.get_pid()}`,
            (res) => {
              if (res.body !== this.userAccountId) {
                this.draggable = false;
                this.$emit("draggable", this.draggable);
                this.notify = this.$notify({
                  title: this.$t("addMindMap.title2"),
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                  message: `<span>${this.$t(
                    "knowledge.TheCurrentKnowledgeBaseChanged"
                  )}<span style="color: blue; cursor: pointer">${this.$t(
                    "addMindMap.btn3"
                  )}</span>${this.$t(
                    "knowledge.SomeFeaturesDisabledUntilThen"
                  )}</span>`,
                  duration: 0,
                  onClick: function () {
                    if (res !== undefined) {
                      self.$router.go(0);
                    }
                  },
                });
              }
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {
            console.log("关闭连接");
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    // 查看收藏的页面
    showdoc(item) {
      this.nodeClick(item, true);
      this.$refs.tree.setCurrentKey(item.documentId);
    },
    // 编辑收藏的页面
    editdoc(item) {
      this.nodeClick(item, true);
      this.$refs.tree.setCurrentKey(item.documentId);
      this.SET_KNOWLEDGE_EDIT({
        knowledge_edit: true,
        knowledge_edit_mode: "edit",
      });
    },
    getCollections() {
      const params = {
        projectId: this.get_pid(),
      };
      getCollections(params).then((res) => {
        this.allSearchData = res;
      });
    },
    // 拖拽相关事件预留
    handleDragStart(node, ev) {},
    handleDragEnter(draggingNode, dropNode, ev) {},
    handleDragLeave(draggingNode, dropNode, ev) {},
    handleDragOver(draggingNode, dropNode, ev) {},
    handleDragEnd(draggingNode, dropNode, dropType, ev) {},
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.creatLoading();
      const documentId = draggingNode.data.documentId;
      this.dragNode = draggingNode;
      this.findLoction(documentId, this.menuList, null);
    },
    allowDrop(draggingNode, dropNode, type) {
      return true;
    },
    allowDrag(draggingNode) {
      return draggingNode.data.authList.length ? true : false;
    },
    creatLoading() {
      this.loading = this.$loading({
        target: "#left-menu",
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //确定被拖拽节点的位置
    findLoction(id, list, parent) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].documentId === id) {
          const params = {
            projectId: this.get_pid(),
            documentId: id,
            data: {
              parentId: parent ? parent : "",
              previousId: i ? list[i - 1].documentId : "",
            },
          };
          location(params).then((res) => {
            this.$emit("updateMenu", () => {
              this.reCheck(id);
              this.loading.close();
              return;
            });
          });
        }
        list[i].children && list[i].children.length
          ? this.findLoction(id, list[i].children, list[i].documentId)
          : "";
      }
    },
    // 点击节点事件
    //flag标识从收藏点击进入 不需要重置选中
    nodeClick(node, flag) {
      const query = {
        documentId: node.documentId,
      };
      node.authList && node.authList.length ? (query.title = node.label) : "";
      this.$route.query && this.$route.query.commentId
        ? (query.commentId = this.$route.query.commentId)
        : "";
      this.$router.push({
        name: "knowledge",
        params: {
          projectId: this.get_pid(),
        },
        query: query,
      });
      this.$emit("checked", node);
      if (
        !this.lastChecked ||
        this.lastChecked.documentId !== node.documentId ||
        flag === true
      ) {
        this.lastChecked = node;
      } else {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(null);
          this.lastChecked = null;
          this.$emit("checked", null);
          this.$router.push({
            name: "knowledge",
            params: {
              projectId: this.get_pid(),
            },
            query: {},
          });
        });
      }
    },
    // 新增事件
    async addNode() {
      if (
        !this.draggable ||
        (this.checkedNode && !this.checkedNode.authList.length)
      ) {
        return;
      }
      if (!this.hasPay) {
        this.$message({
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<span>${this.$t("certificate.Please")}<a href="${
            window.location.origin
          }/profile/certificate_management?target=knowledge" target="_blank" style="color: blue">${this.$t(
            "certificate.CertificateManagement"
          )}</a>${this.$t("certificate.InstallComponents")}</span>`,
        });
        return;
      }
      this.creatLoading();
      const current = this.checkedNode;
      const params = {
        projectId: this.get_pid(),
        data: {
          title: this.$t("knowledge.NewArticle"),
          parentId: "",
          previousId: "",
        },
      };
      if (current) {
        params.data.parentId = current.documentId;
        if (current.children.length) {
          params.data.previousId =
            current.children[current.children.length - 1].documentId;
        }
      }
      if (!current && this.menuList.length) {
        params.data.previousId =
          this.menuList[this.menuList.length - 1].documentId;
      }
      addDocuments(params).then(async (res) => {
        const auth = await getAuth({
          projectId: this.get_pid(),
          documentId: res.documentId,
        });
        res.authList = auth;
        this.$emit("updateMenu", () => {
          this.loading.close();
          this.nodeClick(res, true);
          this.SET_KNOWLEDGE_EDIT({
            knowledge_edit: true,
            knowledge_edit_mode: "add",
          });
        });
      });
    },
    // 左菜单更新后重新选中节点
    reCheck(id) {
      if (id) {
        this.$nextTick().then(() => {
          this.$refs.tree.setCurrentKey(id);
          this.defaultExpandedKeys.push(id);
          this.$emit("checked", this.dragNode.data);
          this.buildDefaultExpandedKeys();
        });
      }
    },
    //收起事件
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
      this.$emit("isCollapse", this.isCollapse);
    },
    // 点击跳转回收站
    toBin() {
      this.$router.push({ path: `/${this.get_pid()}/knowledgeBin` });
    },
    hanlderExpand(node) {
      if (this.defaultExpandedKeys.indexOf(node.documentId) == -1) {
        this.defaultExpandedKeys.push(node.documentId);
      }
    },
    handleCollapse(node) {
      if (this.defaultExpandedKeys.indexOf(node.documentId) != -1) {
        this.defaultExpandedKeys.splice(
          this.defaultExpandedKeys.indexOf(node.documentId),
          1
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.left-menu-content,
.collection-search-wrap {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(242, 246, 250, 1);
  }
}

.createpro-header {
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  font-weight: 400;
  margin-top: -30px;
  margin-bottom: 20px;
  .table-container {
    min-height: 300px;
  }
  .is-active {
    color: #30648f;
  }
  .tab-name {
    font-size: 18px;
    font-weight: 700;
  }
  .article-name {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
  }
}
::v-deep .el-table {
  border: 0;
  th,
  tr,
  td {
    border: 0;
    background-color: #fff;
  }
  &::before {
    height: 0px;
  }
  &::after {
    width: 0;
  }
  .el-table__fixed:before {
    height: 0;
  }
}
.collection-search-wrap {
  & > div {
    height: 32px;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      background-color: rgba(229, 229, 229, 0.44);
    }

    & > span {
      display: inline-block;
      vertical-align: top;

      &:nth-of-type(1) {
        width: 10%;
      }

      &:nth-of-type(2) {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-of-type(3) {
        width: 10%;
      }
    }
  }
}
.layout-icon {
  margin-top: 38px;
  padding: 16px 12px 13px 18px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-menu {
  //width: 300px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(242, 246, 250, 1);

  &-middle {
    text-align: left;
    // padding: 6px 12px 33px 18px;
    // height: 44px;
    & > span {
      margin-right: 10px;
    }
  }

  &-top {
    padding: 16px 12px 13px 18px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .add-button {
      width: 100%;
      height: 40px;
      display: inline-block;
      text-align: center;
      background-color: rgba(229, 229, 229, 1);
      line-height: 40px;
      border-radius: 8px;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
        background-color: rgba(228, 229, 229, 0.8);
      }
    }

    & > .disabled {
      background-color: #909399;

      &:hover {
        cursor: not-allowed;
        background-color: #909399;
      }
    }
  }

  &-content {
    height: calc(100% - 56px - 44px - 84px);
    overflow-y: scroll;
  }

  &-bottom {
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > p {
      cursor: pointer;
      color: rgba(48, 100, 143, 1);
      text-align: left;
      padding-left: 23px;
    }

    & > div {
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  ::v-deep {
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background-color: #c0c4cc !important;
    }
    .el-tree-node__content {
      height: 30px;
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent;
    }

    .el-tree-node__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.left-menu-hide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  text-align: center;

  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.toolPopover {
  min-width: 108px !important;
  #collectionIcon {
    margin-right: 0 !important;
  }
}
</style>
<style lang="scss">
.left-menu-content {
  .el-tree-node__expand-icon {
    color: #174fa9;
  }
  .el-tree-node__expand-icon.is-leaf {
    color: transparent;
  }
}
.collection-popper-class {
  width: 270px;
  padding: 6px 6px 6px 13px;
}
.custom-tree-node {
  font-size: 16px; /*no*/
  display: inline-block;
  width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.mosaic {
  color: rgba(166, 166, 166, 1) !important;
}
</style>
